<template>
  <div>    
        <b-card>
          <b-row>                   
                  <!-- SEARCH INPUT -->
                  <b-col
                  md="6"
                  sm="4"
                  class="d-flex align-items-center justify-content-start"
                  >
                    <b-form-group    
                        class="mb-2"
                    >     
                        <b-form-input
                            id="filterInput"
                            v-model="filter"
                            type="search"
                            placeholder="Type to Search"
                        />
                    </b-form-group>
                  </b-col>
                  <!-- <b-col md="4"/><b-col md="2"/><b-col md="2"/> -->
                  <!-- PAGINATION DROPDOWN -->
                  <b-col
                  md="6" 
                  class="d-flex align-items-center justify-content-end"      
                  >
                    <v-select
                      v-model="perPage"
                      style="width: 90px"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="pageOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block mx-50"
                    />
                  </b-col>
          </b-row>
          <b-row>
                  <!-- TABLE -->
                  <b-col cols="12">
                  <b-table
                      striped
                      hover
                      responsive
                      show-empty
                      empty-text="No matching records found"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :items="getFilterList"
                      :fields="columns"
                      v-model="docCategoryTable"
                      class="categorytable_mgmt p-0_table position-relative"
                  >
                      <template #cell(no)="data">
                          <!-- {{Number(data.index) + 1}} -->
                          {{((currentPage-1) * perPage + data.index + 1)>9?'':0}}{{(currentPage-1) * perPage + data.index + 1}}
                      </template>
                      <template #cell(noOfDoc)="data">
                          {{data.item.noOfDoc.total}}
                      </template>
                      <template #cell(selectedDoc)="data" style="display: flex;width: 40%;">
                        <span  class="text-overflow-span" ref='selectedDoc' :style="data.item.noOfDoc.toggle== null?'width: 300px;':''">
                          {{data.item.selectedDoc.str2}}
                        </span>
                          <a class="btn-overflow" :hidden='data.item.selectedDoc.str2.length < 23' href="#" v-bind:class="{ 'btn-overflowHidden': data.item.noOfDoc.toggle== Number(data.index + 1)}"  @click="data.item.noOfDoc.toggle = Number(data.index + 1)">
                            View more
                          </a>    
                          <a :hidden='data.item.noOfDoc.toggle == null'  @click="data.item.noOfDoc.toggle = null" href="#">
                                View less
                          </a> 
                      </template>
                      <template #cell(action)="data">
                        <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
                          <img src='@/assets/images/erflog/Edit.png' :disabled='isSpiner==true' @click="openEditPage(data)" class="action_icon"/>
                          <img src='@/assets/images/erflog/Delete.png' :disabled='isSpiner==true' @click.prevent="selectedRemoveData=data" v-b-modal.delete-field class="action_icon"/>
                        </div>
                        <div v-else>
                          --
                        </div>
                      </template>                     
                  </b-table>
                  <p v-show="moduleList.length == 0 && isSpiner==false" style="color: red;font-size: small;font-style: italic;text-align: center;">No Data Found</p>
                  </b-col>                              
          </b-row>   
          <!-- <b-row> -->
              <div class="mx-2 mb-2">
                    <b-row>
                        <!-- PAGINATION TEXT -->
                        <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                                <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                        </b-col>
                        
                        <!-- PAGINATION FOOTER -->
                        <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-end"
                        >
                            <b-pagination
                                  v-model="currentPage"
                                  :total-rows="getFilterList.length"
                                  :per-page="perPage"
                                  align="right"
                                  first-number
                                  last-number
                                  prev-class="prev-item"
                                  next-class="next-item"
                                  class="mt-1 mb-0 custom_pagination"
                              >
                              <template #prev-text>
                                <feather-icon
                                  icon="ChevronLeftIcon"
                                  size="18"
                                />
                            </template>
                            <template #next-text>
                              <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                              />
                            </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
              </div>   
          <!-- </b-row>         -->
        </b-card>    
        <!-- <div v-if='isSpiner' class="loading"></div> -->
        <!-- <div v-if="isSpiner" class="loading loading-custom">         
                <div class="effect-1 effects"></div>
                <div class="effect-2 effects"></div>
                <div class="effect-3 effects"></div>     
        </div> -->
        <b-spinner variant="primary" v-if="isSpiner" large class="pageLoadSpinners"/>
        <!-- DELETE MODEL -->
        <b-modal
        id="delete-field"
        cancel-variant="outline-secondary"
        ok-title="Yes"
        cancel-title="No"
        centered
        title=""
        :no-close-on-backdrop="true"
        @hidden="selectedRemoveData=''"
        v-if="selectedRemoveData!=''"
        @ok="deleteField(selectedRemoveData)"
      >
       <div class="create_account_img">
      <img src="@/assets/images/erflog/DeletePopup.png" alt="">
    </div>
      <div style="text-align: center;" v-if="selectedRemoveData!=''">
        <label class="s_modal_title">Delete Field</label>
        <p>Are you sure you want to delete these document category?</p>
      </div>
       </b-modal>
  </div>  
</template>
<script>
// import Vue from 'vue'
import {
  BSpinner ,BModal, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BRow, BCol, BCard, BTable 
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import * as updateQuery from '@/utils/FirebaseQueries/updateQueries/updateQueries.js'
import { dbCollections, settingsCollectionDocs } from '@/utils/firebaseCollection.js';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
export default {
  components: {    
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,   
    VueGoodTable, 
    ToastificationContent,
    vSelect,
    BRow,
    BCol,
    BCard,
    BTable,
    BModal,
    BSpinner    
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      searchTerm:'',
      moduleList:[],
      columns: [
        // {
        //   label: 'No.',
        //   key: 'no',  
        //   width: '150px'
        // },
        {
          label: 'Category Name',
          key: 'moduleName',
          width: '200px',
          sortable:true
        },
        {
          label: 'No. Of Document',
          key: 'noOfDoc',
          width: '220px',
          sortable:true
        },
        {
          label: 'Manage By Student',
          key: 'manageByStudent',
          width: '150px',
          sortable:true
        },
        {
          label: 'Action',
          key: 'action',
          width: '200px'
        },
      ], 
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
      dir: false,  
      toggle:null,
      isSpiner:false,
      removeobj:{},
      allDocuments:[],
      selectedRemoveData:{},
      docCategoryTable:[],
      totalPagination:0   
    }
  },
  watch: {
    $route: function () {     
        this.getData(); 
        this.init();          
    }
   },
  created() {  
    this.getData();
    this.init();    
  },
  methods:{
      getData(){
        this.isSpiner = true
        getQuery.getDataWithSpecificWhereCondition(dbCollections.DOCUMENTS,'isDeleted','==',false,res=>{  
        this.allDocuments = res;
        this.isSpiner = false
        })
      },
      init(){
        this.isSpiner = true
        getQuery.getDataWithSpecificWhereCondition(settingsCollectionDocs.DOCUMENTCATEGORY,'isDeleted','==',false,res=>{   
            this.moduleList = []; 
            // let totalSelectedDoc = [];
            if(res && res.length > 0){
                res.forEach((element,index) => {  
                    // totalSelectedDoc = [];                
                    element.no = {                     
                      'number':JSON.parse(JSON.stringify('0'+Number(index + 1))),
                      'id':element.id
                    };                   
                    let tableDataSelDoc = {
                      'total':element.documents.length,
                      'toggle':null
                    }
                    console.log(element.selDocs,"element.selDocs")
                    this.moduleList.push({
                      'no':element.no,  
                      'moduleName':element.categoryName,
                      'noOfDoc':tableDataSelDoc,
                      'manageByStudent':element.manageByStudent,
                      'action':''         
                    })
                    this.totalPagination =  this.moduleList.length;
                });
                this.isSpiner = false
            }else{
              this.isSpiner = false
            }           
            console.log(this.moduleList,"moduleList")
        })
        // this.isSpiner = false
      },   
      // confirm delete popup and delete module record
      confirmdelete(data) {
        if(this.isSpiner!==true){
        this.removeobj = data;
        this.$swal({
          title: 'Delete',
          text: "Are you sure you want to delete?",
          icon: 'error',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText:'No',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {            
              this.isSpiner = true;
              let id = data.item.no.id;       
              if(id !== undefined){
                let obj = {
                  'isDeleted': true
                }
              this.isSpiner = true;
              updateQuery.updateRootCollectionDataByDocId(settingsCollectionDocs.DOCUMENTCATEGORY,id,obj,res=>{
              console.log(res)                
              this.isSpiner= false;              
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Your file has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              })
            }           
          }
        })
        }
      },
      deleteField(data){
        console.log(data,"GGGG")
        if(this.isSpiner!==true){
        this.removeobj = data;
        this.isSpiner = true;
        let id = data.item.no.id;       
        if(id !== undefined){
          let obj = {
            'isDeleted': true
          }
          this.isSpiner = true;
          updateQuery.updateRootCollectionDataByDocId(settingsCollectionDocs.DOCUMENTCATEGORY,id,obj,res=>{
            console.log(res)                
            this.isSpiner= false;              
            // this.$swal({
            //   icon: 'success',
            //   title: 'Deleted!',
            //   text: 'Your file has been deleted.',
            //   customClass: {
            //     confirmButton: 'btn btn-success',
            //   },
            // })
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                  title: 'Document Remove Successfully',
                  icon: 'CoffeeIcon',
                  variant: 'success',                               
              },
            }) 
          })
        }  
        }
      },
      openEditPage(data){
        if(this.isSpiner!==true){
        let id = data.item.no.id;
        this.$root.$emit('editCategory',true)
        this.$router.push({name:'category-detaile', query: {'editId':id} })          
        }
      },
      onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
      if(this.filter !== ""){
        this.totalPagination = this.docCategoryTable.length;
      }else{
        this.totalPagination = this.moduleList.length;
      }
      },
  },
  computed:{
       dataMeta(){
            const localItemsCount = this.docCategoryTable ? this.docCategoryTable.length : 0
            if(this.filter !== "" && this.filter !== null){
              // console.log("search",this.totalRows)
              return {
                    from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
                    to: this.perPage * (this.currentPage - 1) + localItemsCount,
                    of: this.getFilterList.length,
              }
            }else{
            // console.log("not search")
                 return {
                    from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
                    to: this.perPage * (this.currentPage - 1) + localItemsCount,
                    of: this.moduleList.length,
                 }
            }             
      },
      getFilterList()
      {
        var self = this

        var tmp = self.moduleList
        var dispArr = []

        if(self.filter!='' && self.filter!=null)
        {
          tmp.forEach(data=>{
            if(
              data.moduleName.toLowerCase().includes(self.filter.toLowerCase())
            )
            {
              dispArr.push(data)
            }
          })
        }
        else
        {
          dispArr = tmp
        }

        console.log("Total")
        return dispArr;
      },
  }
}
</script>
 <style lang="scss">
 
.categorytable_mgmt.p-0_table.position-relative.table-responsive {
  padding: 0px !important;
}


.per-page-selector {
  width: 170px;
}
.propsObj{
  visibility: hidden;
}
.text-overflow {
  height: 20px;
  padding: 0;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0 5px 0 5px;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
}
.btn-overflow {
  text-decoration: none;
  overflow: hidden;
}
.btn-overflowHidden{
  display: none; 
  text-decoration: none
}


@-webkit-keyframes rotate {
  /* 100% keyframe for  clockwise. 
     use 0% instead for anticlockwise */
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>